/* Footer.css */
.site-footer {
    background: #0f172a;
    color: #fff;
    padding: 4rem 0 2rem;
    margin-top: auto;
}

.footer-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1.5rem;
}

.footer-top {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-brand {
    margin-right: 2rem;
}

.footer-brand img{
    width: 35%;
    margin-bottom: 10px;
}

.logo {
    font-size: 1.5rem;
    font-weight: 700;
    color: #fff;
    margin-bottom: 0.5rem;
}

.tagline {
    color: #94a3b8;
    margin-bottom: 1.5rem;
}

.social-links {
    display: flex;
    gap: 1rem;
}

.social-icon {
    color: #94a3b8;
    transition: color 0.3s ease;
}

.social-icon:hover {
    color: #6366f1;
}

.social-icon svg {
    width: 24px;
    height: 24px;
}

.footer-column h4 {
    font-size: 1.125rem;
    margin-bottom: 1.5rem;
    color: #fff;
}

.footer-column ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-column li {
    margin-bottom: 0.75rem;
}

.footer-column a {
    color: #94a3b8;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-column a:hover {
    color: #6366f1;
}

.newsletter-form {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.newsletter-form input {
    padding: 0.75rem;
    border-radius: 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.05);
    color: #fff;
}

.newsletter-form button {
    padding: 0.75rem;
    background: linear-gradient(170deg, #3954ff 0, #617eff 78%, #c0e0ff 100%);
    color: #fff;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background 0.3s ease;
}

.newsletter-form button:hover {
    background: linear-gradient(210deg, #3954ff 0, #617eff 78%, #c0e0ff 100%);
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2rem;
    margin-top: 2rem;
    flex-wrap: wrap;
    gap: 1rem;
}

.copyright {
    color: #94a3b8;
    font-size: 0.875rem;
    margin: 0;
}

.copyright_link {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.copyright_link:hover {
    color: #6366f1;
    text-decoration: underline;
}

.payment-methods {
    display: flex;
    gap: 1rem;
}

/* Custom toast styling */
.Toastify__toast {
    border-radius: 8px !important;
    box-shadow: 0 4px 12px rgba(0,0,0,0.15) !important;
    min-height: 60px !important;
}

.Toastify__toast-body {
    font-family: 'Arial', sans-serif;
    font-size: 15px;
    display: flex;
    align-items: center;
}

.Toastify__progress-bar {
    background: rgba(255,255,255,0.3) !important;
}

@media (max-width: 768px) {
    .footer-top {
        grid-template-columns: repeat(2, 1fr);
    }

    .footer-bottom {
        flex-direction: column;
        text-align: center;
    }
    .footer-brand img{
        width: 65%;
    }
}

@media (max-width: 480px) {
    .footer-top {
        grid-template-columns: 1fr;
    }
}