:root {
    --primary: #1a1a1a;
    --secondary: #FF4D4D;
    --accent: #f5f5f5;
    --text: #333;
    --transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.navbar {
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    height: 70px;
    top: 0;
    z-index: 1000;
}

.navbar-container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 0.75rem; */
    cursor: pointer;
    margin: 0;
}

.header-logo{
    /* background-color: #3954ff; */
    width: 200px;
}

.header-logo img{
    width: 32%;
}

.logo-link img{
    width: 90%;
    background-size: cover;
    /* object-fit: fill; */
}

.nav-links {
    display: flex;
    gap: 2.5rem;
    align-items: center;
}

.nav-item {
    position: relative;
    font-weight: 500;
    color: var(--text);
    text-decoration: none;
    padding: 0.5rem 0;
    transition: var(--transition);
}

.underline {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #3954ff;
    /* background: var(--secondary); */
    transition: var(--transition);
}

.nav-item:hover .underline {
    width: 100%;
}

.nav-item:hover {
    background-color: transparent;
}

.action-section {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.icon-btn {
    background: none;
    border: none;
    cursor: pointer;
    position: relative;
    font-size: 1.4rem;
    color: var(--text);
    padding: 0.5rem;
    display: grid;
    place-items: center;
}


.nav-item:hover, .icon-btn:hover, .mobile-item:hover {
    transform: none !important;
}

.icon-btn:hover .badge{
    position: absolute;
    top: -6px!important;
    right: -6px!important;
}

.badge {
    position: absolute;
    top: -6px!important;
    right: -6px!important;
    background: var(--secondary);
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    transition: none !important;
    transform: none !important;
}

.menu-toggle {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.8rem;
    margin-left: 1rem;
}

.navbar-search-container {
    position: absolute;
    right: 240px;
    /* Adjust this value based on your needs */
    top: 50%;
    transform: translateY(-50%);
    z-index: 1001;
}

.navbar-search-container input {
    width: 100%;
    padding: 0.8rem 1.5rem;
    border: 2px solid var(--accent);
    border-radius: 30px;
    font-size: 1rem;
    transition: var(--transition);
}

.navbar-search-container input:focus {
    outline: none;
    border-color: var(--secondary);
    box-shadow: 0 0 15px rgba(255, 77, 77, 0.2);
}

.close-search {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    color: var(--secondary);
}

.profile-section {
    position: relative;
}

.profile-icon {
    font-size: 20px !important;
    color: var(--primary)!important;
    cursor: pointer;
}

.profile-icon:hover {
    transform: scale(1);
    /* color: var(--secondary)!important; */
}

.profile-dropdown {
    background-color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 130px;
    position: absolute;
    top: 30px;
    right: -20px;
    font-size: 18px;
    padding: 15px 0;
    row-gap: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
}

.profile-item{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-left: 25px;
}


.profile-dropdown button{
    padding-left: 30px;
}

.profile-dropdown button .icon{
    font-size: 15px;
}

.sign-in{
    /* color: var(--secondary); */
    color: rgb(43, 43, 255);
    font-size: 1.2rem;
    /* font-weight: 600; */
    text-decoration: none;
}

.sign-in:hover{
    text-decoration: underline;
}

/* Mobile Menu */
.mobile-menu {
    position: fixed;
    top: 80px;
    right: -100%;
    width: 100%;
    height: calc(100vh - 80px);
    background: rgba(255, 255, 255, 0.98);
    backdrop-filter: blur(15px);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    transition: right 0.3s cubic-bezier(0.4, 0, 0.2, 1); /* Add transition */
    z-index: 999;
}

.mobile-menu.active {
    right: 0; /* Slide in */
    opacity: 1;
}

.mobile-menu {
    opacity: 0;
    transition: right 0.3s cubic-bezier(0.4, 0, 0.2, 1), 
                opacity 0.2s ease-in-out;
}

.mobile-menu.active .mobile-item {
    opacity: 1;
    transform: translateY(0);
}

/* Staggered delays */
.mobile-menu.active .mobile-item:nth-child(1) { transition-delay: 0.1s; }
.mobile-menu.active .mobile-item:nth-child(2) { transition-delay: 0.2s; }
.mobile-menu.active .mobile-item:nth-child(3) { transition-delay: 0.3s; }
.mobile-menu.active .mobile-item:nth-child(4) { transition-delay: 0.4s; }
.mobile-menu.active .mobile-item:nth-child(5) { transition-delay: 0.5s; }

.mobile-item {
    font-size: 1.5rem;
    color: var(--text);
    text-decoration: none;
    padding: 1rem;
    border-radius: 12px;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.mobile-item:hover {
    /* background: rgba(255, 77, 77, 0.1); */
    transform: translateX(10px);
}

/* Add this to your CSS */
.content-wrapper {
    padding-top: 70px;
    /* Adjust this value based on your navbar height */
    /* min-height: calc(100vh - 80px); */
}

.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    /* Remove any transform properties */
}


@media (max-width: 1024px) {
    .nav-links {
        display: none;
    }

    .menu-toggle {
        display: block;
    }

    .navbar-container {
        padding: 1rem 1.5rem;
    }

    .logo-text {
        font-size: 1.5rem;
    }

    .action-section {
        gap: 1rem;
    }
}

@media (max-width: 992px) {
    .mobile-menu{
        top: 70px;
    }
}

@media (max-width: 480px) {
    .navbar-search-container {
        right: 120px;
        width: 150px;
    }

    .navbar-search-container input {
        padding: 0.6rem 1rem;
    }

    /* .mobile-menu {
        top: 100px;
        height: calc(100vh - 70px);
    } */
}